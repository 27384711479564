export default {
  "nav": {
    "complains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaints and suggestions"])},
    "whous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads reports"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wallet"])},
    "Business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business and CV"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploaded files"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orders"])},
    "stores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["digital stores"])},
    "elcservices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["electronic services"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["digital services"])},
    "elcservicesdef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["services"])},
    "prologinmob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["provider"])},
    "prologin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login as provider"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login as user"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profile"])},
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
  },
  "footer": {
    "footl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved to maak lancer"])},
    "footr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designed and programmed by AAIT"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send"])},
    "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your email here"])},
    "emailPlacereg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email here"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cart"])},
    "noti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notifications"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my reviews"])},
    "fav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact us"])},
    "contactSpe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscribe to our newsletter"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about the platform"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us on"])}
  },
  "shared": {
    "waitinforaccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request to join the administration has been sent. Please wait for approval"])},
    "confirmPassPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm your password"])},
    "infoaboutService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info About Service"])},
    "infoaboutProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info About Provider"])},
    "confirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm password"])},
    "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Details"])},
    "acceptedDirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The service provider has accepted your request"])},
    "semiaccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for the service provider to accept your request"])},
    "orderstate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Status"])},
    "orderfinish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm completion"])},
    "ordernego": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negotiation situation"])},
    "finishedall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
    "orderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order Type"])},
    "orderDetailsser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["service details"])},
    "walletPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amount"])},
    "walletPriceplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please enter amount"])},
    "orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order Date"])},
    "orderdur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration of service implementation"])},
    "allplustax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total + added tax"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "payminiword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "finishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finishing up"])},
    "addrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Rate"])},
    "prodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["provider information"])},
    "showchat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Chat"])},
    "addratenew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Rate"])},
    "addratephase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please rate your experience"])},
    "notnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Now"])},
    "cannego": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can negotiate now"])},
    "waitclient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for the customer to accept your quote"])},
    "performing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underway"])},
    "addwaitprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for a price quote to be added"])},
    "acceptword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "refuseseviceword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])},
    "finishforce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm order completion"])},
    "noteall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The price displayed for the service request will be in addition to the value of the added tax"])},
    "SelectsectionPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a section"])},
    "Selectsection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select section"])},
    "cityPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a city"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "regProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account as a provider"])},
    "Forgotpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
    "passPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your password"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "loginProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in as a provider"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resend"])},
    "notSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't receive the code?"])},
    "varifing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifying"])},
    "varifay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["varify"])},
    "keycodePhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the verification code sent to your mobile number"])},
    "keycode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation code"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving"])},
    "profilePhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can modify your personal information from here"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["register"])},
    "registering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registering"])},
    "agreeTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree to "])},
    "regNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register a new account as a user"])},
    "pleaseCont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please contact us"])},
    "haveProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a problem while registering?"])},
    "newAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Account"])},
    "haveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do you have an account?"])},
    "donthaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["don't have an account?"])},
    "loginloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
    "loginParse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the following data so you can log in to your account"])},
    "design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
    "refusesevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse service"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept service"])},
    "servicetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Time"])},
    "serviceprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service price"])},
    "showprofile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View profile"])},
    "cancelorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel order"])},
    "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers"])},
    "currentorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current orders"])},
    "finishedorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finished orders"])},
    "waitingpay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["being paid"])},
    "waitingorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for offers"])},
    "nostores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no stores to display yet"])},
    "nofiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no files uploaded yet"])},
    "designer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["designer"])},
    "detailsshow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show details"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "messageplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter message details from here"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "phoneplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the mobile number"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "usernameplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your username here"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "Pleasecontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact us so that you can solve your problems and be able to log in"])},
    "complains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaints and suggestions"])},
    "allPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price"])},
    "productsPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products Price"])},
    "addedPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added tax"])},
    "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activate"])},
    "cartPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the discount coupon here"])},
    "payNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Now"])},
    "cartdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cart details"])},
    "reyal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SR"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download price"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cart"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
    "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chats"])},
    "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["client name"])},
    "orderNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order number"])},
    "currentorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current orders"])},
    "neworder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new orders"])},
    "allorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All orders"])},
    "best": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best selling Products"])},
    "bankbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bank info"])},
    "banknameplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the bank name"])},
    "bankname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bank name"])},
    "faina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial transactions"])},
    "bankholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Holder's Name"])},
    "bankholderplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the name of the account holder"])},
    "banknumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])},
    "banknumberplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the account number"])},
    "ibanNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iban Number"])},
    "ibanNumberplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the IBAN number"])},
    "bothproviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital products and digital services"])},
    "noChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no chats yet"])},
    "noCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Cart is empty"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "ServiceRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Request"])},
    "choosePay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose one of the following methods to complete the payment process"])},
    "onlinePay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["online payment"])},
    "walletPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with wallet"])},
    "donePay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment completed successfully"])},
    "doneRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully removed from cart"])},
    "invoiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An invoice has been issued"])},
    "invoiceTitleword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add An invoice "])},
    "finishingword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish Order"])},
    "waitapprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Pending approval)"])},
    "noproducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no products yet"])},
    "addNewPro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new product"])},
    "prodname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name"])},
    "prodnameplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the business name"])},
    "mainSec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main section"])},
    "mainsecplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the main partition"])},
    "featuresall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
    "featuresallplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose product attributes"])},
    "productPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Price"])},
    "productPriceplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the product price"])},
    "saleperc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discount percentage"])},
    "salepercplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the discount percentage"])},
    "priceafter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The price after discount"])},
    "priceafterplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the price after discount"])},
    "endsale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount expiration date"])},
    "endsaleplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the discount expiration date"])},
    "productPhaseplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the product description"])},
    "applyphoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach photos for display to clients"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach"])},
    "applydown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach photos/file for download"])},
    "addproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Product"])},
    "addingproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading"])},
    "waitadminaccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The product has been added successfully, awaiting administration approval of the product"])},
    "priceInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price value after negotiation"])},
    "invoiceDur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The period specified for carrying out the work"])},
    "invoiceAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Price due"])},
    "chatPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["white message here"])},
    "clientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["client Info"])},
    "endOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Offer"])},
    "askendOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to complete the request?"])},
    "endOfferword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
    "messagesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All your conversations are displayed here"])},
    "favEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fav is Empty"])},
    "addfav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully added to favorites"])},
    "noImgs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no Imgs"])},
    "downFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download files"])},
    "downFilesname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back"])},
    "proAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider Account"])},
    "payword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment"])},
    "negWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negotiation request"])},
    "chooseway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try one of the following procedures to complete your application"])},
    "noNotis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no notifications yet"])},
    "addOrdertest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Oreder"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow"])},
    "following": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Following"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "Pricework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "showRates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Rates"])},
    "infoAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about me"])},
    "productstext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "worksshow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business exhibition"])},
    "Ratestext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
    "noRates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no reviews yet"])},
    "Requestdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request form data"])},
    "ProjectDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project details"])},
    "addPhotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach photos"])},
    "Workdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work details"])},
    "Worklink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work link"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
    "workDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration of work"])},
    "productDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Details"])},
    "DownloadPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product download price"])},
    "Downloadnum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of downloads"])},
    "productPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Description"])},
    "addCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add To Cart"])},
    "doneOrderSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The request has been sent successfully, awaiting approval from the service provider"])},
    "addedCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added to cart successfully"])},
    "addComplain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Complain"])},
    "detailsPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write the complaint details here"])},
    "removeFav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully removed from favorites"])},
    "followedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followed successfully"])},
    "providersword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providers"])},
    "providerword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider"])},
    "seachhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Here"])},
    "noProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Providers yet"])},
    "priceRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price range"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "durationDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "durationDaysplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write the required period of work here"])},
    "doneGlobal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The request has been sent successfully. Waiting for service providers' quotations"])},
    "searchValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Ressults"])},
    "unfollowedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unFollowed successfully"])},
    "complainReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason Of Complaint"])},
    "complainDeails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaint details"])},
    "filterword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search filter"])},
    "priceWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "proName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider Name"])},
    "NoOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Orders Yet"])},
    "highPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The highest price"])},
    "lowPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The lowest price"])},
    "Ratetext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rate"])},
    "Highrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest rated"])},
    "lowrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest rated"])},
    "attr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feature"])},
    "currentWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current balance"])},
    "reyalsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SR"])},
    "chargewallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["charge Wallet"])},
    "reasonPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the complaint Reason"])},
    "ProjectDetailsplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write the project details from here"])},
    "canceldone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The request has been successfully cancelled"])},
    "cancelsure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel the order?"])},
    "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platform with you"])}
  },
  "modals": {
    "loginFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must log in first"])},
    "successChangePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The phone number has been changed successfully. Please log in again"])},
    "phonePhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please click on Send to verify the old mobile number"])},
    "oldphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Phone"])},
    "newPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Phone"])},
    "newPhonePhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the new mobile number"])},
    "newPhonePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the new mobile number"])},
    "doneSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["changes were saved successfully"])},
    "bothProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital products and digital services"])},
    "resetPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "resetPassphase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your new password to be able to log in"])},
    "curentPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
    "currentPassplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Current Password"])},
    "newPassplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the new password"])},
    "newPassconfirmplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm your password"])},
    "newPassconfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm the new password"])},
    "danePassChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been changed successfully. Please log in again"])},
    "newPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "phonePassphase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your mobile number to have your verification code sent so you can change your password"])}
  }
}