<template>
  <div class="all-product-d">
    <div class="container">
      <div class="top-links mt-4 mb-4 font14">
        {{ $t("nav.Home") }} - {{ $t("nav.elcservicesdef") }} -
        {{ categoryTop }} -
        <span class="color-main">{{ $t("shared.orderDetailsser") }}</span>
      </div>
      <h5 class="font-bold mb-4">{{ $t("shared.orderDetailsser") }}</h5>
      <div class="row mb-4" v-if="!loader">
        <div class="col-lg-6 mb-4">
          <template v-if="images != null">
            <div
              class="slider-details"
              :class="{
                noarrows: images.length < 2,
                noarrowshide: images.length < 5,
              }"
            >
              <Galleria
                :value="images"
                :autoPlay="true"
                :circular="true"
                :responsiveOptions="responsiveOptions"
                :numVisible="images.length < 4 ? images.length : 4"
                containerStyle="max-width: 640px"
              >
                <template #item="slotProps">
                  <img
                    :src="slotProps.item.link"
                    :alt="slotProps.item.alt"
                    style="width: 100%"
                    class="height-slider"
                  />
                  <div class="abs-sm-img">
                    <Image
                      class="sm-img"
                      :src="slotProps.item.link"
                      preview
                      alt="Image"
                    />
                  </div>
                  <div class="abd-readonly">
                    <i class="fa-regular fa-eye"></i>
                  </div>
                </template>
                <template #thumbnail="slotProps">
                  <img
                    :src="slotProps.item.link"
                    :alt="slotProps.item.alt"
                    class="small-img-d"
                  />
                </template>
              </Galleria>
            </div>
          </template>
          <template v-else>
            <div class="img-logo-wall detailforproduct">
              يتم هنا عرض صور المنتج ان وجدت
            </div>
          </template>
        </div>
        <div class="col-lg-6 mb-4">
          <div class="flex-bet-main mb-3">
            <h5 class="font-bold mb-3">{{ $t("shared.infoaboutProvider") }}</h5>
          </div>

          <div class="flex-bet-main mb-4" v-if="!provider">
            <div class="flex-sm-gap no-align">
              <img :src="providerDataimage" class="miniuser" alt="" />
              <div>
                <div class="font-bold mb-3">{{ providerDataname }}</div>
                <div class="rate-main-me px-2">
                  <star-rating
                    :show-rating="false"
                    :rtl="true"
                    :rating="Number(providerDatarate)"
                    :star-size="20"
                    :read-only="true"
                    :rounded-corners="true"
                    :increment="0.5"
                    active-color="#ffc107"
                  ></star-rating>
                </div>
              </div>
            </div>
            <router-link
              :to="{
                name: 'providerProfile',
                params: { id: $route.params.id },
              }"
              class="link-best-de underline-main"
              >{{ $t("shared.showprofile") }}</router-link
            >
          </div>

          <h5 class="font-bold mb-3">{{ $t("shared.infoaboutService") }}</h5>
          <p>{{ desc }}</p>
          <div class="flex-big-gap yes-wrap mt-4">
            <div>
              <h5 class="mb-3">{{ $t("shared.serviceprice") }}</h5>
              <p class="gray-col">{{ price }}</p>
            </div>
            <div>
              <h5 class="mb-3">{{ $t("shared.duration") }}</h5>
              <p class="gray-col">{{ duration }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5" v-else>
        <div class="col-md-6">
          <div class="height-slider">
            <Skeleton width="100%" height="400px"></Skeleton>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-5 flex-bet-main">
            <Skeleton width="8rem" height="1rem"></Skeleton>
            <Skeleton width="2rem" height="2rem"></Skeleton>
          </div>
          <div class="mb-4">
            <Skeleton width="16rem" height="1rem"></Skeleton>
          </div>
          <div class="mb-4">
            <Skeleton width="8rem" height="1rem"></Skeleton>
          </div>
          <div class="mb-4 flex-bet-main">
            <Skeleton width="8rem" height="1rem"></Skeleton>
            <Skeleton width="8rem" height="1rem"></Skeleton>
          </div>
          <div class="mb-4">
            <Skeleton width="8rem" height="1rem"></Skeleton>
          </div>
          <div class="flex-bet-main" v-if="!provider">
            <div class="mb-5 flex-sm-gap">
              <Skeleton width="6rem" shape="circle" height="6rem"></Skeleton>
              <div>
                <Skeleton width="10rem" class="mb-2" height="1rem"></Skeleton>
                <Skeleton width="10rem" height="1rem"></Skeleton>
              </div>
            </div>
            <Skeleton width="9rem" height="1rem"></Skeleton>
          </div>
          <div class="flex-group-me" v-if="provider">
            <Skeleton width="12rem" height="3rem"></Skeleton>
            <Skeleton width="12rem" height="3rem"></Skeleton>
          </div>
          <div v-else class="row">
            <div class="col-md-3 col-3 mb-3">
              <Skeleton width="100%" height="3rem"></Skeleton>
            </div>
            <div class="col-md-9 col-12 mb-3">
              <Skeleton width="100%" height="3rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Galleria from "primevue/galleria";
import StarRating from "vue-star-rating";
import axios from "axios";
import Image from "primevue/image";
import Toast from "primevue/toast";
import Skeleton from "primevue/skeleton";
export default {
  data() {
    return {
      cartVal: 1,
      visible: false,
      value: 3,
      valuemain: null,
      proImg: require("../../assets/imgs/user.jpg"),
      successImg: require("../../assets/imgs/succ.png"),
      platImg: require("../../assets/imgs/Rectangle 30471.png"),
      bestImg: require("../../assets/imgs/user.jpg"),
      usermini: require("../../assets/imgs/user.jpg"),
      defaultUser: require("../../assets/imgs/solia.jpg"),
      //   images: null,
      responsiveOptions: [
        {
          breakpoint: "991px",
          numVisible: 4,
        },
        {
          breakpoint: "767px",
          numVisible: 3,
        },
        {
          breakpoint: "575px",
          numVisible: 1,
        },
      ],
      images: [],
      comment: "",
      title: "",
      price: "",
      priceafter: "",
      sale: "",
      downLoadNum: "",
      rate: "",
      desc: "",
      loader: false,
      discount_expire: "",
      providerData: null,
      disabled: false,
      user: false,
      provider: false,
      is_favorite: false,
      providerId: null,
      products: [],

      providerDataid: null,
      providerDataname: "",
      providerDataimage: "",
      providerDatarate: "",
      duration: "",
      has_discount: false,
    };
  },
  components: {
    Galleria,
    StarRating,
    Image,
    Toast,
    Skeleton,
  },
  methods: {
    async getProduct() {
      this.loader = true;
      const fd = new FormData();
      fd.append("provider_id", this.$route.params.id);
      fd.append("service_id", this.$route.params.num);
      await axios
        .post(`/user/services/provider/info`, fd, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response.data.data);
          this.categoryTop = response.data.data.category.name;
          this.images = response.data.data.attachments;

          this.providerDataimage = response.data.data.provider.image;
          this.providerDataname = response.data.data.provider.name;
          this.providerDataid = response.data.data.provider.id;
          this.providerDatarate = response.data.data.provider.rate;
          this.providerId = response.data.data.id;
          this.desc = response.data.data.description;
          this.duration = response.data.data.duration;
          this.price = response.data.data.price;
        })
        .catch((err) => {
          console.log(err);
        });
      this.loader = false;
    },
  },

  mounted() {
    this.getProduct();
  },
};
</script>

<style></style>
