<template>
  <div class="files-section">
    <div class="container">
      <div class="top-links mt-4 mb-4 font14">
        {{ $t("nav.Home") }} -
        <span class="color-main">{{ $t("nav.wallet") }}</span>
      </div>
      <h5 class="mb-4 font-bold">{{ $t("nav.wallet") }}</h5>
      <div class="col-md-10 margin-auto">
        <div class="row mb-4 align-items-center">
          <div class="col-md-6 col-12 mb-3">
            <div class="pad-main-pad">
              <div class="wallet-box">
                <div class="gray-col mb-3">
                  {{ $t("shared.currentWallet") }}
                </div>
                <div class="wallet-price mb-3" v-if="!loading">
                  {{ balance }}
                </div>
                <div class="flex-group-me mb-3" v-else>
                  <Skeleton width="10rem" height="1rem"></Skeleton>
                </div>
                <div>{{ $t("shared.reyalsa") }}</div>
              </div>
            </div>
            <button
              type="button"
              data-bs-target="#walletModal"
              data-bs-toggle="modal"
              class="main-btn up full-wid"
            >
              {{ $t("shared.chargewallet") }}
            </button>
          </div>
          <div class="col-md-6 col-12 mb-3">
            <div class="img-wall">
              <img :src="bestImg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--wallet modal-->
  <div
    class="modal fade"
    id="walletModal"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button
          type="button"
          class="close-model-btn speproduct"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="fa-solid fa-xmark"></i>
        </button>
        <div class="main-input-cont mb-3 mt-5">
          <h6 class="mb-2 font14">{{ $t("shared.walletPrice") }}</h6>
          <input
            type="number"
            class="main-input no-right"
            :placeholder="$t('shared.walletPriceplace')"
            v-model="amount"
          />
        </div>
        <div class="flex-group-me mt-4 mb-5">
          <button type="button" class="main-btn up" @click="chargeNow" :disabled="disabled">
            <div v-if="!disabled">{{ $t("shared.chargewallet") }}</div>
            <div class="loading" role="status" v-if="disabled">
              <div class="spinner-small-white" v-if="disabled"></div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Skeleton from "primevue/skeleton";
export default {
  data() {
    return {
      bestImg: require("../../assets/imgs/wallet.png"),
      loading: false,
      balance: "0:00",
      disabled: false,
      amount: "",
    };
  },
  components: { Skeleton },
  methods: {
    async getBalance() {
      this.loading = true;
      await axios
        .post(`/wallet/balance`, "", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.balance = response.data.data;
        });
      this.loading = false;
    },
    async chargeNow() {
      this.disabled = true;
      const fd = new FormData();
      fd.append("amount", this.amount);

      await axios
        .post("/user/frontend-charge-wallet", fd, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data.key == "success") {
            window.location = response.data.data.url;
          } else {
            this.$toast.add({
              severity: "error",
              detail: `${response.data.msg}`,
              life: 3000,
            });
          }
        });
      this.disabled = false;
    },
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.getBalance();
    }
  },
};
</script>

<style></style>
