export default {
  "nav": {
    "complains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقتراحات"])},
    "whous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير طلبات التحميل"])},
    "Business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاعمال والسيرة الذاتية"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحفظة"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملفات المحملة"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات"])},
    "stores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتاجر الرقمية"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات الرقمية"])},
    "elcservices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات الالكترونية"])},
    "elcservicesdef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات "])},
    "prologinmob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مقدم خدمة"])},
    "prologin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل كمقدم خدمة"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدم"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل كمستخدم"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب الشخصي"])},
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])}
  },
  "footer": {
    "footl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة لمنصة معك"])},
    "footr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم وبرمجة اوامر الشبكة"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال"])},
    "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري الارسال"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
    "emailPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب ايميلك هنا"])},
    "emailPlacereg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال  البريد الالكتروني هنا"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والاحكام"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلة"])},
    "noti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التنبيهات"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييماتي"])},
    "fav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفضلة"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "contactSpe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشتراك في آخر الاخبار"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن منصة معك"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعنا على"])}
  },
  "shared": {
    "waitinforaccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم ارسال طلبك للانضمام للادارة برجاء انتظار الموافقة"])},
    "confirmPassPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تاكيد كلمة المرور"])},
    "infoaboutService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الخدمة"])},
    "infoaboutProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن مقدم الخدمة"])},
    "currentWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصيد الحالي"])},
    "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الطلب"])},
    "orderDetailsser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الخدمة"])},
    "addratenew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة تقييم"])},
    "clientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات العميل"])},
    "orderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الطلب"])},
    "addratephase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء وضع تقييمك على التجربة"])},
    "prodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات مقدم الخدمة"])},
    "notnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس الان"])},
    "cannego": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك التفاوض الان"])},
    "waitclient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في انتظار قبول العميل عرض السعر المقدم منك"])},
    "orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الطلب"])},
    "performing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد التنفيذ"])},
    "addwaitprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في انتظار اضافة عرض سعر"])},
    "orderdur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة تنفيذ الخدمة"])},
    "acceptedDirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم قبول مقدم الخدمة الطلب المقدم منك"])},
    "semiaccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في انتظار قبول مقدم الخدمة الطلب المقدم منك"])},
    "reyalsa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ريال سعودي"])},
    "chargewallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شحن المحفظة"])},
    "confirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكيد كلمة المرور"])},
    "SelectsectionPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء اختيار القسم"])},
    "Selectsection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيارالقسم"])},
    "cityPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء اختيار المدينة"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
    "walletPriceplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال المبلغ"])},
    "regProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء حساب جديد كمقدم خدمة"])},
    "Forgotpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور"])},
    "passPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال كلمة المرور"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "loginProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول كمقدم خدمة"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعد ارسال"])},
    "notSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تستلم الكود؟"])},
    "varifing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري التحقق"])},
    "varifay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق"])},
    "keycodePhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال كود التحقق الذي تم ارساله الى رقم الجوال الخاص بك"])},
    "keycode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود التحقق"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ التغييرات"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري الحفظ"])},
    "profilePhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك تعديل معلوماتك الشخصية من هنا"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء حساب"])},
    "registering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري الانشاء"])},
    "agreeTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموافقة على "])},
    "regNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل حساب جديد كعميل"])},
    "pleaseCont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء التواصل معنا"])},
    "haveProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك مشكلة اثناء التسجيل؟"])},
    "newAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب جديد"])},
    "faina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معاملات مالية"])},
    "donthaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك حساب؟"])},
    "haveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك حساب؟"])},
    "loginloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري التسجيل"])},
    "loginParse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال البيانات التالية حتى تتمكن من تسجيل الدخول الى حسابك"])},
    "design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم"])},
    "refusesevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفض الخدمة"])},
    "refuseseviceword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفض"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول الخدمة"])},
    "acceptword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول"])},
    "servicetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الخدمة"])},
    "serviceprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الخدمة"])},
    "walletPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
    "showprofile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عرض الملف الشخصي"])},
    "cancelorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الغاء الطلب"])},
    "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العروض"])},
    "currentorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات الحالية"])},
    "finishedorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات المنتهية"])},
    "waitingpay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد الدفع"])},
    "finishedall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتهي"])},
    "waitingorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بانتظار العروض"])},
    "nostores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد متاجر لعرضها حتى الان"])},
    "nofiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد ملفات محملة حتى الان"])},
    "designer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصمم"])},
    "detailsshow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التفاصيل"])},
    "NoOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد طلبات حتى الان"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "noproducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد منتجات حتى الان"])},
    "addNewPro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة منتج جديد"])},
    "prodname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المنتج"])},
    "prodnameplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فضلا ادخال اسم العمل"])},
    "mainSec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القسم الرئيسي"])},
    "mainsecplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر القسم الرئيسي"])},
    "featuresall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السمات"])},
    "featuresallplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر سمات المنتج"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال"])},
    "messageplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال تفاصيل الرسالة من  هنا"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
    "phoneplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال رقم الجوال"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "usernameplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم المستخدم هنا"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
    "Pleasecontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء التواصل معنا لتتمكن من حل مشاكلكم وتستطيع تسجيل الدخول"])},
    "complains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الشكاوي والمقترحات"])},
    "allPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمال شامل الضريبة"])},
    "addedPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضريبة المضافة"])},
    "productsPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر المنتجات"])},
    "productPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر المنتج"])},
    "saleperc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الخصم"])},
    "salepercplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال نسبة الخصم"])},
    "productPriceplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال سعر المنتج"])},
    "productstext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات"])},
    "worksshow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرض الاعمال"])},
    "priceafter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر بعد الخصم"])},
    "priceafterplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال السعر بعد الخصم "])},
    "endsale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ انتهاء الخصم"])},
    "endsaleplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال تاريخ انتهاء الخصم"])},
    "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل"])},
    "cartPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كوبون الخصم من هنا"])},
    "payNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادفع الان"])},
    "cartdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل السلة"])},
    "reyal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ر.س"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر التحميل"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلة"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
    "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحادثات"])},
    "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العميل"])},
    "orderNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطلب"])},
    "orderstate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الطلب"])},
    "orderfinish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكيد الانتهاء"])},
    "ordernego": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة تفاوض"])},
    "currentorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات الحالية"])},
    "neworder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات الجديدة"])},
    "allorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الطلبات"])},
    "best": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات الاكثر مبيعا"])},
    "bankbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الحساب البنكي"])},
    "bankbtnplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم البنك"])},
    "bankname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم البنك"])},
    "bankholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم صاحب الحساب"])},
    "bankholderplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم صاحب الحساب"])},
    "banknumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الحساب"])},
    "banknumberplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال رقم الحساب"])},
    "ibanNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الايبان"])},
    "ibanNumberplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال رقم الايبان"])},
    "banknameplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم البنك"])},
    "bothproviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتجات رقمية وخدمات رقمية"])},
    "noChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد محادثات حتى الان"])},
    "noCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلة فارغة"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري التحميل"])},
    "ServiceRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب خدمة"])},
    "onlinePay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع الكتروني"])},
    "walletPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع بالمحفظة"])},
    "donePay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الدفع بنجاح"])},
    "doneRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الحذف من السلة بنجاح"])},
    "invoiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اصدار فاتورة"])},
    "invoiceTitleword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اصدار فاتورة"])},
    "priceInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة المبلغ بعد التفاوض"])},
    "invoiceDur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدة المحددة لتنفيذ العمل"])},
    "invoiceAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي المبلغ المستحق"])},
    "chatPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رسالتك هنا"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسائل"])},
    "messagesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم هنا عرض جميع المحادثات الخاصة بك"])},
    "favEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفضلة فارغة"])},
    "choosePay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاءاختيار احد الطرق التالية لاستكمال عملية الدفع"])},
    "chooseway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختبار احد الاجرائيين التاليين لاستكمال طلبكم"])},
    "addfav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الاضافة للمفضلة بنجاح"])},
    "noImgs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تتوفر صور"])},
    "waitapprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(قيد الموافقة)"])},
    "downFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل الملفات"])},
    "downFilesname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل"])},
    "noNotis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد اشعارات حتى الان"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اغلاق"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجوع"])},
    "payword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع"])},
    "payminiword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع"])},
    "negWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب تفاوض"])},
    "finishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري الانهاء"])},
    "finishingword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انهاء الطلب"])},
    "addrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف تقييم"])},
    "showchat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض محادثة"])},
    "finishforce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكيد انتهاء الطلب"])},
    "canceldone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الغاء الطلب بنجاح"])},
    "cancelsure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متاكد برغبتك بالغاء الطلب؟"])},
    "proAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب مقدم الخدمة"])},
    "addOrdertest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة طلب"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])},
    "following": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتابعه"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة العمل"])},
    "Pricework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر العمل"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملحوظة"])},
    "noteall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر المعروض لطلب الخدمة يكون مضاف اليه قيمة الضريبة المضافة"])},
    "allplustax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالي + الضريبة المضافة"])},
    "showRates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التقييمات"])},
    "Ratestext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييمات"])},
    "Ratetext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييم"])},
    "Highrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاعلى تقييما"])},
    "lowrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقل تقييما"])},
    "attr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السمة"])},
    "noRates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد تقييمات حتى الان"])},
    "infoAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عني"])},
    "Requestdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات نموذج الطلب"])},
    "ProjectDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل المشروع"])},
    "addPhotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفاق صور"])},
    "Workdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل العمل"])},
    "Worklink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط العمل"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوم"])},
    "workDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة العمل"])},
    "productDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل المنتج"])},
    "DownloadPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر تحميل المنتج"])},
    "Downloadnum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد مرات التحميل"])},
    "productPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف المنتج"])},
    "productPhaseplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فضلا ادخل وصف المنتج"])},
    "applyphoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفاق صور للعرض للعملاء"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفاق"])},
    "applydown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفاق صور/ملف للتحميل"])},
    "addCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة للسلة"])},
    "addproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة المنتج"])},
    "addingproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري الاضافة"])},
    "waitadminaccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اضافة المنتج بنجاح , بانتظار موافقة الادارة على المنتج"])},
    "addedCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الاضافة للسلة بنجاح"])},
    "addComplain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة شكوى"])},
    "endOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انهاء العرض"])},
    "endOfferword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انهاء"])},
    "askendOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متاكد من انهاء الطلب؟"])},
    "complainReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبب الشكوى"])},
    "complainDeails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الشكوى"])},
    "reasonPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك ادخل تفاصيل الشكوى"])},
    "detailsPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فضلا كتابة  تفاصيل الشكوى من  هنا"])},
    "removeFav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الازالة من المفضلة بنجاح"])},
    "followedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت المتابعة بنجاح"])},
    "unfollowedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت الغاء المتابعة بنجاح"])},
    "doneOrderSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم ارسال الطلب بنجاح في انتظار موافقة مقدم الخدمة"])},
    "ProjectDetailsplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فضلا كتابة  تفاصيل المشروع  هنا"])},
    "providersword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدمين الخدمة"])},
    "providerword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدم خدمة"])},
    "seachhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث هنا"])},
    "searchValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتائج البحث"])},
    "noProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد مقدمي خدمة"])},
    "priceRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رينج الاسعار"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الى"])},
    "durationDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدة"])},
    "filterword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلتر البحث"])},
    "priceWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
    "highPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاعلى سعر"])},
    "lowPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقل سعر"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعادة تعيين"])},
    "exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستوى مقدم الخدمة"])},
    "proName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم مقدم الخدمة"])},
    "durationDaysplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فضلا كتابة المدة المطلوبة للعمل هنا"])},
    "doneGlobal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم ارسال الطلب بنجاح في انتظار عروض اسعار مقدمي الخدمة"])},
    "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منصة معك"])}
  },
  "modals": {
    "loginFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب تسجيل الدخول اولا"])},
    "successChangePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تغيير رقم الهاتف بنجاح يرجى اعادة تسجيل الدخول"])},
    "phonePhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى الضغط على ارسال للتحقق من رقم الجوال القديم"])},
    "oldphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال القديم"])},
    "newPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال الجديد"])},
    "newPhonePhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك قم بكتابة رقم الجوال الجديد"])},
    "newPhonePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك قم بكتابة رقم الجوال الجديد"])},
    "doneSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حفظ التغييرات بنجاح"])},
    "bothProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حفظ التغييرات بنجاح"])},
    "resetPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعادة تعيين كلمة المرور"])},
    "resetPassphase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال كلمة المرور الجديدة لتتمكن من تسجيل الدخول"])},
    "curentPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الحالية"])},
    "currentPassplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال كلمة المرور الحالية"])},
    "newPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة"])},
    "danePassChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تغيير كلمة المرور بنجاح من فضلك اعد تسجيل الدخول"])},
    "newPassconfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكيد كلمة المرور الجديدة"])},
    "newPassconfirmplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تاكيد كلمة المرور"])},
    "newPassplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال كلمة المرور الجديدة"])},
    "phonePassphase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الجوال ليتم ارسال كود التحقق الخاص بك لتتمكن من تغيير كلمة المرور"])}
  }
}